import React from 'react';


const Chooseus = () => {
  return (
    <>
    <div className="container dfty" id="flats">
    <div className="about aboutheader-1" id="about">Why Choose Us</div>
    
    <div align="center" className="border">
      <img className="img-responsive" src="img/border.jpg" alt='aboutheader' />
    </div>
    &nbsp;
    
    <p className="about-qwe"></p>
    
    <div className="row">
    <div className="col-sm-6 col-md-6">
    <div className="wow zoomIn animated" data-wow-delay="200ms" data-wow-duration="400ms">
    <ul className="pricing featured">
      <li><img src="img/chooseus/icon8.png" alt=' On Time Service'/><strong> On Time Service</strong></li>
      <li><img src="img/chooseus/icon9.png" alt='Best & Transparent Prices'/><strong> Best &amp; Transparent Prices</strong></li>
    </ul>
    </div>
    </div>
    
    <div className="col-sm-6 col-md-6">
    <div className="wow zoomIn animated" data-wow-delay="200ms" data-wow-duration="400ms">
    <ul className="pricing featured">
      <li><img src="img/chooseus/icon10.png" alt='Skilled & Trained Professionals' /><strong> Skilled &amp; Trained Professionals </strong></li>
      <li><img src="img/chooseus/icon11.png" alt=' Assured Service Quality' /><strong> Assured Service Quality </strong></li>
    </ul>
    </div>
    </div>
    </div>
    </div>
    <p></p>
    <p></p>

    </>
  );
}

export default Chooseus;